@import "../scss/variables";


/*--Default Body--*/

body {
    &.default-body {
      background: $background;
  
      &.dark-mode {
        background: $dark-theme;
      }
    }
  
    &.card-shadow {
      &.default-body {
        background: #eff0f6;
      }
  
      &.white-body {
        background: $white;
      }
    }
  
    &.white-body {
      background: $white;
  
      .card {
        border: 1px solid #e8eaf7;
      }
  
      &.dark-mode {
        background: #10163a;
  
        .card {
          border: 1px solid $white-1;
        }
      }
    }
  
    &.card-shadow.light-dark-body {
      background: #edf3f7;
    }
	&.card-shadow.dark-mode {
		background:#1c2248;
	}
	&.card-shadow .header.top-header{
		box-shadow:none;
		border-bottom: 1px solid #eff0f6;
	}
	&.card-shadow .horizontalMenucontainer .header.top-header{
		box-shadow:none;
		border-bottom: 0px solid #eff0f6;
	}
	&.card-shadow .horizontal-main{
		box-shadow:none;
	}
	&.card-shadow .app-sidebar{
		box-shadow:none;
	}
    &.light-dark-body {
      background: #edf3f7;
  
      &.dark-mode {
        background: #1c2248;
      }
    }
  
    &.light-card .card {
      background: #f4f5fc;
    }
  
    &.card-shadow {
      background: #eff0f6;
  
      .card {
        box-shadow: none !important;
      }
    }
  
    &.card-radius .card {
      border-radius: 0;
    }
  }
  
  /*--White Body--*/
  
  /*--Light Dark Body--*/
  
  /*---default Horizontal--*/
  
  .default-horizontal {
    .horizontal-main {
      background: $white;
      background: $white;
    }
  
    .hor-icon {
      color: #8e98db !important;
      fill: #d2d6f1 !important;
    }
  
    .horizontalMenu > .horizontalMenu-list > li {
      > a {
        color: $color;
  
        &.active {
          color: $primary;
          text-decoration: none;
          background: #f4f5fc;
        }
  
        border-left: 1px solid rgb(239, 240, 246);
      }
  
      &:last-child > a {
        border-right: 1px solid rgb(239, 240, 246);
      }
  
      > a {
        &:hover .hor-icon {
          color: $primary !important;
          fill: #d2d6f1;
        }
  
        &.active .hor-icon, &:hover {
          color: $primary !important;
        }
      }
    }
  }
  @media only screen and (max-width: 991px){
	.default-horizontal.dark-mode .horizontal-main{
		background: #10163a;
	}
	.default-horizontal.dark-mode .horizontalMenu > .horizontalMenu-list {
		background:$white;
	}
	.default-horizontal.dark-mode .horizontalMenu > .horizontalMenu-list > li {
		background-color: $white;
	}
	.default-horizontal.dark-mode .horizontalMenu > .horizontalMenu-list > li > a{
		color:$color;
		background-color: $white;
	}
	.default-horizontal.dark-mode .horizontalMenu > .horizontalMenu-list > li > ul.sub-menu{
		color:$color;
		background-color: $white;
	}
	.default-horizontal.dark-mode .horizontalMenu > .horizontalMenu-list > li > ul.sub-menu > li > a{
		color:$color;
	}
	.default-horizontal.dark-mode .mega-menubg{
		color:$color;
		background-color: $white !important;
	}
	.default-horizontal.dark-mode .horizontalMenu > .horizontalMenu-list > li > .horizontal-megamenu .link-list li a{
		color:$color;
	}
	.default-horizontal.dark-mode .horizontalMenu > .horizontalMenu-list > li > .horizontalMenu-click > i{
		color:$black-5;
	}
	.default-horizontal.light-mode .horizontal-main{
		background: $white;
	}
	.default-horizontal.light-mode .horizontalMenu > .horizontalMenu-list {
		background:$white;
	}
	.default-horizontal.light-mode .horizontalMenu > .horizontalMenu-list > li {
		background-color: $white;
	}
	.default-horizontal.light-mode .horizontalMenu > .horizontalMenu-list > li > a{
		color:$color;
		background-color: $white;
	}
	.default-horizontal.light-mode .horizontalMenu > .horizontalMenu-list > li > ul.sub-menu{
		color:$color;
		background-color: $white;
	}
	.default-horizontal.light-mode .horizontalMenu > .horizontalMenu-list > li > ul.sub-menu > li > a{
		color:$color;
	}
	.default-horizontal.light-mode .mega-menubg{
		color:$color;
		background-color: $white !important;
	}
	.default-horizontal.light-mode .horizontalMenu > .horizontalMenu-list > li > .horizontal-megamenu .link-list li a{
		color:$color;
	}
	.default-horizontal.light-mode .horizontalMenu > .horizontalMenu-list > li > .horizontalMenu-click > i{
		color:$black-5;
	}
}
  
  /*---dark Horizontal--*/
  .dark-horizontal .horizontalMenu > .horizontalMenu-list > li:hover a .hor-icon{
	color: $white-8 !important;
      fill: $white-2;
  }
  .dark-horizontal {
    .horizontal-main {
      background: #10163a;
      background: #10163a;
    }
  
    .hor-icon {
      color: $white-8;
      fill: $white-2;
    }
  
    .horizontalMenu > .horizontalMenu-list > li {
      > a {
        color: $white-8;
  
        &.active {
          color: $white;
          text-decoration: none;
          background: rgba(255,255,255,0.04);
        }
  
        border-left: 1px solid rgb(32, 38, 71);
      }
  
      &:last-child > a {
        border-right: 1px solid rgb(32, 38, 71);
      }
  
      > a {
        &:hover .hor-icon {
          color: rgb(255, 255, 255) !important;
          fill: $white-5;
        }
  
        &.active .hor-icon {
          color: rgb(255, 255, 255) !important;
        }
  
        &:hover {
          color: $white !important;
        }
      }
    }
  }
   @media only screen and (max-width: 991px){
	.dark-horizontal.dark-mode .horizontal-main{
		background: #10163a;
	}
	.dark-horizontal.dark-mode .horizontalMenu > .horizontalMenu-list {
		background:#10163a;
	}
	.dark-horizontal.dark-mode .horizontalMenu > .horizontalMenu-list > li {
		background-color: #10163a;
		border-color:$white-1;
	}
	.dark-horizontal.dark-mode .horizontalMenu > .horizontalMenu-list > li > a{
		color:$white-8;
		background-color: #10163a;
	}
	.dark-horizontal.dark-mode .horizontalMenu > .horizontalMenu-list > li > ul.sub-menu{
		color:$white-8;
		background-color: #10163a;
	}
	.dark-horizontal.dark-mode .horizontalMenu > .horizontalMenu-list > li > ul.sub-menu > li > a{
		color:$white-8;
	}
	.dark-horizontal.dark-mode .mega-menubg{
		color:$white-8;
		background-color: #10163a !important;
	}
	.dark-horizontal.dark-mode .horizontalMenu > .horizontalMenu-list > li > .horizontal-megamenu .link-list li a{
		color:$white-8;
	}
	.dark-horizontal.dark-mode .horizontalMenu > .horizontalMenu-list > li > .horizontalMenu-click > i{
		color:$white-5;
	}
	.dark-horizontal.light-mode .horizontal-main{
		background: #10163a;
	}
	.dark-horizontal.light-mode .horizontalMenu > .horizontalMenu-list {
		background:#10163a;
	}
	.dark-horizontal.light-mode .horizontalMenu > .horizontalMenu-list > li {
		background-color: #10163a;
		border-color:$white-1;
	}
	.dark-horizontal.light-mode .horizontalMenu > .horizontalMenu-list > li > a{
		color:$white-8;
		background-color: #10163a;
	}
	.dark-horizontal.light-mode .horizontalMenu > .horizontalMenu-list > li > ul.sub-menu{
		color:$white-8;
		background-color: #10163a;
	}
	.dark-horizontal.light-mode .horizontalMenu > .horizontalMenu-list > li > ul.sub-menu > li > a{
		color:$white-8;
	}
	.dark-horizontal.light-mode .mega-menubg{
		color:$white-8;
		background-color: #10163a !important;
	}
	.dark-horizontal.light-mode .horizontalMenu > .horizontalMenu-list > li > .horizontal-megamenu .link-list li a{
		color:$white-8;
	}
	.dark-horizontal.light-mode .horizontalMenu > .horizontalMenu-list > li > .horizontalMenu-click > i{
		color:$white-5;
	}
}
  
  /*---color Horizontal--*/
  .color-horizontal .horizontalMenu > .horizontalMenu-list > li:hover a .hor-icon{
	color: $white-8 !important;
      fill: $white-2;
  }
  .color-horizontal {
    .horizontal-main {
      background: $primary;
      background: $primary;
    }
  
    .hor-icon {
      color: $white-8 !important;
      fill: $white-2;
    }
  
    .horizontalMenu > .horizontalMenu-list > li {
      > a {
        color: $white-8;
  
        &.active {
          color: $white;
          text-decoration: none;
          background: rgba(255, 255, 255, 0.04);
        }
		&.hover  {
			.hor-icon {
			  color: $white-8 !important;
			  fill: $white-2;
			}
		}
        border-left: 1px solid rgb(87, 101, 201);
      }
  
      &:last-child > a {
        border-right: 1px solid rgb(87, 101, 201);
      }
  
      > a {
        &:hover .hor-icon {
          color: rgb(255, 255, 255) !important;
          fill: $white-5;
        }
  
        &.active .hor-icon {
          color: rgb(255, 255, 255) !important;
        }
  
        &:hover {
          color: $white !important;
        }
      }
    }
  }
    @media only screen and (max-width: 991px){
	.color-horizontal.dark-mode .horizontal-main{
		background: #10163a;
	}
	.color-horizontal.dark-mode .horizontalMenu > .horizontalMenu-list {
		background:$primary;
	}
	.color-horizontal.dark-mode .horizontalMenu > .horizontalMenu-list > li {
		background-color: $primary;
		border-color:$white-1;
	}
	.color-horizontal.dark-mode .horizontalMenu > .horizontalMenu-list > li > a{
		color:$white-8 !important;
		background-color: $primary;
	}
	.color-horizontal.dark-mode .horizontalMenu > .horizontalMenu-list > li > ul.sub-menu{
		color:$white-8;
		background-color: $primary;
	}
	.color-horizontal.dark-mode .horizontalMenu > .horizontalMenu-list > li > ul.sub-menu > li > a{
		color:$white-8;
	}
	.color-horizontal.dark-mode .mega-menubg{
		color:$white-8;
		background-color: $primary !important;
	}
	.color-horizontal.dark-mode .horizontalMenu > .horizontalMenu-list > li > .horizontal-megamenu .link-list li a{
		color:$white-8;
	}
	.color-horizontal.dark-mode .horizontalMenu > .horizontalMenu-list > li > .horizontalMenu-click > i{
		color:$white-5;
	}
	.color-horizontal.light-mode .horizontal-main{
		background: $primary;
	}
	.color-horizontal.light-mode .horizontalMenu > .horizontalMenu-list {
		background:$primary;
	}
	.color-horizontal.light-mode .horizontalMenu > .horizontalMenu-list > li {
		background-color: $primary;
		border-color:$white-1;
	}
	.color-horizontal.light-mode .horizontalMenu > .horizontalMenu-list > li > a{
		color:$white-8 !important;
		background-color:$primary;
	}
	.color-horizontal.light-mode .horizontalMenu > .horizontalMenu-list > li > ul.sub-menu{
		color:$white-8;
		background-color: $primary;
	}
	.color-horizontal.light-mode .horizontalMenu > .horizontalMenu-list > li > ul.sub-menu > li > a{
		color:$white-8;
	}
	.color-horizontal.light-mode .mega-menubg{
		color:$white-8;
		background-color: $primary !important;
	}
	.color-horizontal.light-mode .horizontalMenu > .horizontalMenu-list > li > .horizontal-megamenu .link-list li a{
		color:$white-8;
	}
	.color-horizontal.light-mode .horizontalMenu > .horizontalMenu-list > li > .horizontalMenu-click > i{
		color:$white-5;
	}
}
	
@media only screen and (max-width: 767px) and (min-width:568px){
	.app.light-mode .app-header .header-brand .header-brand-img.mobile-logo {
		display:none;
	}
	.app.light-mode .app-header .header-brand .header-brand-img.darkmobile-logo {
		display:none;
	}
	.app.light-mode .app-header .header-brand .header-brand-img.desktop-lgo {
		display:block !important;
		margin-top:6px;
	}
	.app.light-mode .app-header .header-brand .header-brand-img.dark-logo{
		display:none;
	}
}
@media only screen and (max-width: 567px) {
	.app.light-mode .app-header .header-brand .header-brand-img.desktop-lgo{
		display:none !important;
	}
	.app.light-mode .app-header .header-brand .header-brand-img.mobile-logo {
		display:block !important;
		margin-top:8px;
	}
}

/*-- Dark-sidebar  closed---*/
.dark-sidebar.dark-mode {
  .app-sidebar {
    background: #10163a !important;
    border-right: 1px solid rgba(255, 255, 255, 0.07);
    box-shadow: 0px 10px 10px 20px rgba(38, 43, 72, 0.11), 10px 10px 15px -5px rgba(38, 43, 72, 0.13);
  }

  .app-sidebar__logo {
    border-bottom: 1px solid #2c3650;
  }

  .app-sidebar__user {
    border-bottom: 1px solid rgba(255, 255, 255, 0.07);
    color: #ffffff;

    .text-muted {
      color: rgba(255, 255, 255, 0.4) !important;
    }
  }

  .slide a {
    color: #edf0f5 !important;
    background: none;
  }

  .app-sidebar-help {
    border-top: 1px solid rgba(255, 255, 255, 0.07);
    background: #10163a;

    a {
      color: #fff !important;
    }

    .header-icon {
      color: rgba(255, 255, 255, 0.8);
      fill: rgba(255, 255, 255, 0.8);
    }
  }

  .side-menu__icon {
    color: rgba(255, 255, 255, 0.8);
    fill: rgba(255, 255, 255, 0.08);
  }
}

/*-- Color-sidebar toggle ---*/
.color-sidebar {
	.app-sidebar.toggle-sidemenu{
		background: none !important;
		border-right: 0;
		box-shadow: none;
		width: inherit;
	}
  .first-sidemenu {
    background: $primary;
  }

  .second-sidemenu {
    background: $primary;
    border-right: 1px solid rgba(255, 255, 255, 0.07);
  }

  .resp-vtabs .resp-tabs-list li {
    border: 1px solid rgba(255, 255, 255, 0.07) !important;
    border-left: 0 !important;
    border-top: 0 !important;
    border-bottom: 0;
  }

  .first-sidemenu li {
    &.active, &:hover {
      background: rgba(255, 255, 255, 0.03);
      border-right: 0 !important;
    }
  }

  .side-menu__icon {
    color: rgba(255, 255, 255, 0.8);
    fill: rgba(255, 255, 255, 0.08);
  }

  .first-sidemenu li {
    &.active svg, &:hover svg {
      color: rgba(255, 255, 255, 0.8);
      fill: rgba(255, 255, 255, 0.08);
    }
  }

  .second-sidemenu h5, .slide-item, .side-menu .slide.submenu a {
    color: rgba(255, 255, 255, 0.8);
  }

  .slide-item {
    &.active, &:hover {
      color: #fff;
    }
  }

  .side-menu .slide.submenu a {
    &.active, &:hover {
      color: #fff;
    }
  }

  .second-sidemenu .angle {
    color: rgba(255, 255, 255, 0.2) !important;
  }
}

/*--- Dark-sidebar toggle ----*/
.dark-sidebar {
	.app-sidebar.toggle-sidemenu{
		background: none !important;
		border-right: 0;
		box-shadow: none;
		width: inherit;
	}
  .first-sidemenu {
    background: #10163a;
  }

  .second-sidemenu {
    background: #10163a;
    border-right: 1px solid rgba(255, 255, 255, 0.07);
    box-shadow: none;
  }

  .resp-vtabs .resp-tabs-list li {
    border: 1px solid rgba(255, 255, 255, 0.07) !important;
    border-left: 0 !important;
    border-top: 0 !important;
    border-bottom: 0;
  }

  &.app.sidebar-mini.sidenav-toggled .first-sidemenu li.active {
    border-right: 1px solid rgba(255, 255, 255, 0.07) !important;
  }

  .first-sidemenu {
    .side-menu__icon {
      color: rgba(255, 255, 255, 0.8);
      fill: rgba(255, 255, 255, 0.08);
    }

    li.active {
      background: #12183f;
      border-right: 0 !important;
    }
  }

  .resp-vtabs .resp-tab-active:hover {
    background: #12183f;
    border-right: 0 !important;
  }

  .second-sidemenu h5 {
    color: rgba(255, 255, 255, 0.8);
  }

  .first-sidemenu li {
    &.active svg, &:hover svg {
      color: $primary;
    }
  }

  .side-menu .slide.submenu a, .slide-item {
    color: #edf0f5;
  }
}

/*--- Light-sidebar toggle ---*/
.default-sidebar {
	.app-sidebar.toggle-sidemenu{
		background: none !important;
		border-right: 0;
		box-shadow: none;
		width: inherit;
	}
  .first-sidemenu {
    background: #fff;
  }

  .second-sidemenu {
    background: #fff;
    border-right: 1px solid #eff0f6;
    box-shadow: 0px 10px 10px 20px rgba(176, 184, 214, 0.01), 10px 10px 15px -5px #b0b8d6;
  }

  .resp-vtabs .resp-tabs-list li {
    border: 1px solid #eff0f6 !important;
    border-left: 0 !important;
    border-top: 0 !important;
    border-bottom: 0;
  }

  &.app.sidebar-mini.sidenav-toggled .first-sidemenu li.active {
    border-right: 1px solid #eff0f6 !important;
  }

  .first-sidemenu {
    .side-menu__icon {
      color: #8e98db;
      fill: #d2d6f1;
    }

    li.active {
      background: #f4f5fc;
      border-right: 0 !important;
    }
  }

  .resp-vtabs .resp-tab-active:hover {
    background: #f4f5fc;
    border-right: 0 !important;
  }

  .second-sidemenu h5 {
    color: #576483;
  }

  .first-sidemenu li {
    &.active svg, &:hover svg {
      color: $primary;
    }
  }

  .side-menu .slide.submenu a, .slide-item {
    color: #576482;
  }
}
.default-sidebar.dark-mode  .second-sidemenu{
	box-shadow:none;
}

/*-- Color-sidebar closed-menu ---*/
.color-sidebar {
  .app-sidebar {
    background: #4454c3 !important;
    border-right: 1px solid rgba(255, 255, 255, 0.07);
    box-shadow: 0px 10px 10px 20px rgba(38, 43, 72, 0.11), 10px 10px 15px -5px rgba(38, 43, 72, 0.13);
  }

  .app-sidebar__logo {
    border-bottom: 1px solid rgba(255, 255, 255, 0.07);
  }

  .app-sidebar__user {
    border-bottom: 1px solid rgba(255, 255, 255, 0.07);
    color: #ffffff;

    .text-muted {
      color: rgba(255, 255, 255, 0.4) !important;
    }
  }

  .slide a {
    color: rgba(255, 255, 255, 0.8) !important;
    background: none;

    &.active, &:hover {
      color: #fff !important;
    }
  }

  .app-sidebar-help {
    border-top: 1px solid rgba(255, 255, 255, 0.07);
    background: #4454c3;

    a {
      color: #fff !important;
    }

    .header-icon {
      color: rgba(255, 255, 255, 0.8);
      fill: rgba(255, 255, 255, 0.8);
    }
  }

  .side-menu__icon {
    color: rgba(255, 255, 255, 0.8);
    fill: rgba(255, 255, 255, 0.08);
  }

  .slide-menu a {
    &.active, &:hover {
      color: #fff !important;
      background: transparent;
    }
  }

  .side-menu__label:hover {
    color: #fff !important;
    background: transparent;
  }

  .side-menu__item {
    &.active .side-menu__icon, &:hover .side-menu__icon, &:focus .side-menu__icon {
      color: rgba(255, 255, 255, 0.8);
      fill: rgba(255, 255, 255, 0.08);
    }
  }
}

@media (min-width: 768px) {
  .color-sidebar .app-sidebar2 .header-brand-img {
    &.desktop-lgo, &.mobile-logo, &.darkmobile-logo, &.desktop-lgo, &.mobile-logo, &.darkmobile-logo {
      display: none;
    }

    &.dark-logo {
      display: block;
      margin: 0 auto;
      margin-top: 5px;
    }
  }
}


/*-- light-sidebar closed-menu ---*/
.default-sidebar {
  .app-sidebar {
    background: #fff !important;
    border-right: 1px solid #eff0f6;
    box-shadow: 0px 10px 10px 20px rgba(176, 184, 214, 0.09), 10px 10px 15px -5px #b0b8d6;
  }

  .app-sidebar__logo {
    border-bottom: 1px solid #eff0f6;
  }

  .app-sidebar__user {
    border-bottom: 1px solid #eff0f6;
    color: #424e79;

    .text-muted {
      color: #b3bdca  !important;
    }
  }

  .slide a {
    color: #576482 !important;
    background: none;

    &.active, &:hover {
      color: #4454c3 !important;
    }
  }

  .app-sidebar-help {
    border-top: 1px solid #eff0f6;
    background: #fff;

    a {
      color: #424e79 !important;
    }

    .header-icon {
      color: #8e98db;
      fill: #d2d6f1;
    }
  }

  .side-menu__icon {
    color: #8e98db;
    fill: #d2d6f1;
  }

  .slide-menu a {
    &.active, &:hover {
      color: #4454c3 !important;
      background: transparent;
    }
  }

  .side-menu__label:hover {
    color: #4454c3 !important;
    background: transparent;
  }

  .side-menu__item {
    &.active .side-menu__icon, &:hover .side-menu__icon, &:focus .side-menu__icon {
      color: #4454c3;
    }
  }

  &.dark-mode .app-sidebar {
    border-right: 0;
    box-shadow: none;
  }
}

@media (min-width: 768px) {
  .default-sidebar .app-sidebar2 .header-brand-img {
    &.dark-logo, &.mobile-logo, &.darkmobile-logo, &.dark-logo, &.mobile-logo, &.darkmobile-logo {
      display: none;
    }

    &.desktop-lgo {
      display: block;
      margin: 0 auto;
      margin-top: 5px;
    }
  }
}


/*-- Dark-sidebar closed-menu ---*/
.dark-sidebar {
  .app-sidebar {
    background: #10163a !important;
    border-right: 1px solid rgba(255, 255, 255, 0.07);
    box-shadow: 0px 10px 10px 20px rgba(38, 43, 72, 0.11), 10px 10px 15px -5px rgba(38, 43, 72, 0.13);
  }

  .app-sidebar__logo {
    border-bottom: 1px solid #2c3650;
  }

  .app-sidebar__user {
    border-bottom: 1px solid rgba(255, 255, 255, 0.07);
    color: #ffffff;

    .text-muted {
      color: rgba(255, 255, 255, 0.4) !important;
    }
  }

  .slide a {
    color: #edf0f5 !important;
    background: none;
  }

  .app-sidebar-help {
    border-top: 1px solid rgba(255, 255, 255, 0.07);
    background: #10163a;

    a {
      color: #fff !important;
    }

    .header-icon {
      color: rgba(255, 255, 255, 0.8);
      fill: rgba(255, 255, 255, 0.8);
    }
  }

  .side-menu__icon {
    color: rgba(255, 255, 255, 0.8);
    fill: rgba(255, 255, 255, 0.08);
  }
}
@media (min-width: 768px) {
  .dark-sidebar .app-sidebar2 .header-brand-img {
    &.desktop-lgo, &.mobile-logo, &.darkmobile-logo, &.desktop-lgo, &.mobile-logo, &.darkmobile-logo {
      display: none;
    }

    &.dark-logo {
      display: block;
      margin: 0 auto;
      margin-top: 5px;
    }
  }
}
.dark-sidebar {
  .side-menu__item {
    &.active .side-menu__icon, &:hover .side-menu__icon, &:focus .side-menu__icon {
      fill: rgba(255, 255, 255, 0.08);
    }
  }

  .slide a {
    color: #edf0f5 !important;
    background: none;
  }
}

.light-mode.dark-sidebar .slide a, .dark-mode.dark-sidebar .slide a {
  color: #edf0f5 !important;
  background: none;
}
.dark-sidebar.sidenav-toggled .app-sidebar2 .dark-logo, .color-sidebar.sidenav-toggled .app-sidebar2 .dark-logo {
	display:none;
}
.dark-sidebar.sidenav-toggled .app-sidebar2 .darkmobile-logo, .color-sidebar.sidenav-toggled .app-sidebar2 .darkmobile-logo{
	display: block;
    margin: 0 auto;
    margin-top: 5px;
}
.default-sidebar.sidenav-toggled .app-sidebar2 .desktop-lgo{
	display:none;
}
.default-sidebar.sidenav-toggled .app-sidebar2 .mobile-logo{
	display: block;
    margin: 0 auto;
    margin-top: 5px;
}
.light-mode.dark-sidebar {
  .side-menu__item {
    &.active, &:hover, &:focus {
      text-decoration: none;
      color: $primary !important;
    }
  }

  .slide a {
    &.active, &:hover, &:focus {
      text-decoration: none;
      color: $primary !important;
    }
  }
}

.dark-mode.dark-sidebar {
  .side-menu__item {
    &.active, &:hover, &:focus {
      text-decoration: none;
      color: $primary !important;
    }
  }

  .slide a {
    &.active, &:hover, &:focus {
      text-decoration: none;
      color: $primary !important;
    }
  }
}

.dark-mode {
  .horizontalMenu > .horizontalMenu-list > li > {
    ul.sub-menu > li > {
      a:hover, ul.sub-menu > li > a:hover {
        color: $primary;
      }
    }

    .horizontal-megamenu .link-list li a:hover {
      color: $primary;
    }
  }

  .second-sidemenu .side-menu .slide.submenu a {
    &.active, &:hover {
      color: $primary !important;
    }
  }
}